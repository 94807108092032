import React from "react"
import { ContactFormSubmitterDetails } from "../contactUs/ConfactFormSubmitterDetails"
import ContactFormMessage from "../contactUs/ContactFormMessage"
import ArrowButton from "../ArrowButton"
import { useField, FieldArray } from "formik"
import styles from '../../styles/careers/ApplyToPositionForm.module.css'

export interface Props {
  questions: string[]
  handleSubmit: () => void;
}

export const ApplyToPositionForm = ({questions, handleSubmit}: Props): JSX.Element => {
  const [field, meta, helpers] = useField('questions')
  
  return <>
    <ContactFormSubmitterDetails lastField="phone" lastFieldLabel="Phone Number" lastFieldRequired={false} dark />
    <FieldArray name="questions" render={(arrayHelpers) => {
      return questions.map((question: string, index: number) => (
        <div key={question} className={styles.fieldContainer}>
        <label>{question}</label>
        <ContactFormMessage light fieldName={`questions.${index}.answer`}></ContactFormMessage>
        </div>
      ))
    }}></FieldArray>
    <div className={styles.actionButtonContainer}>

                  <ArrowButton handleClick={ handleSubmit }>Send
                  it</ArrowButton>
    </div>
  </>
}