import React, { useState } from 'react';
import '../styles/index.css';
import PageLayout from "../components/PageLayout";
import { graphql } from "gatsby";
import { CareerPageQuery } from '../../graphql-types'
import styles from '../styles/careers/Career.module.css'
import { Form, Formik, FormikHelpers, useField } from 'formik';
import * as Yup from 'yup'
import { ApplyToPositionForm } from '../components/careers/ApplyToPositionForm';
import { Image } from 'rebass';
import FSDialog from '../components/FSDialog';
import CheckMarkIcon from '../components/svg/CheckMarkIcon';
import contactStyles from '../styles/ContactForm.module.css'

const BlockContent = require('@sanity/block-content-to-react')

interface Props {
  data: CareerPageQuery;
  pageContext: {
    name: string
    index: number
  };
}

interface ContactValuesQuestion {
  question: string
  answer: string
}

interface ContactValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  questions: ContactValuesQuestion[];
}

const Career = ({data, pageContext: {index}}: Props) => {
  const [ open, setOpen ] = useState(false)
  const positions = data.sanityCareers?._rawOpenPositions

  if (!positions || !positions[index]) {
    return null
  }


  const submit = async (values: ContactValues, { resetForm }: FormikHelpers<ContactValues>) => {
    const data = new FormData()
    Object.keys(values).forEach((key) => {
      if (key === 'questions') {
        (values[key] as ContactValuesQuestion[]).forEach(({question, answer}) => {
          data.append(question.replace(/_/, ' '), answer)
        })
      } else {
        data.append(key, values[key as keyof ContactValues])
      }
    })
    
    // @ts-ignore
    const res = await window.fetch('https://getform.io/f/7ecaaf87-590e-43f0-a2e8-5da97b8ee619', {
      method: 'post',
      body: data,
      dataType: 'json',
      headers: {
        "Accept": "application/json"
      },
      mode: 'cors',
    })
    if (res.status === 200) {
      resetForm()
      setOpen(true)
    } else {
      // error handling?
    }
  }

  const {name, description, questions} = positions[index]!

  const createValidationSchema = () => {
    const questionsSchema: { [key: string]: Yup.StringSchema<string> } = {}
    
    questions.forEach((question: string) => {
      questionsSchema[question] = Yup.string().required('Required')
    })

    return Yup.object().shape({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      email: Yup.string().required('Required').email('Please enter a valid email address'),
      phone: Yup.string(),
      questions: Yup.array().of(Yup.object().shape({
        question: Yup.string(),
        answer: Yup.string().required('Required'),
      }))
    })
  }

  const getInitialValues = () => {
    const questionsValues = questions.map((question: string) => {
      return {
        question,
        answer: ''
      }
    })

    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      questions: questionsValues
    }
  }

  const handleThanksClose = () => {
    setOpen(false)
  }
  
  return (
    <PageLayout title={`${name} | Careers | FS Woodworks`}>
      <section className={ styles.container }>
        <h1 className={ styles.header }>
          Careers
          <Image
                  src='/images/career-hammer-time.png'
                  className={styles.stamp}
                />
          </h1>
        <div className={styles.contentContainer}>
          <div className={styles.position}>
            <p className={styles.positionNumber}>0{index + 1}</p>
            <p className={styles.positionName}>{name}</p>
          </div>
          <h2 className={styles.subheader}>{name}</h2>
          <div className={styles.description}>
            <BlockContent blocks={ description }/>
          </div>
          <p className={styles.description}>
            Please fill out and submit this application and our hiring team will contact you within 48 hours.
          </p>

          <Formik initialValues={ getInitialValues() } onSubmit={ submit }
              validationSchema={ createValidationSchema() }>
              {({handleSubmit}) => (
                <Form>
                  <ApplyToPositionForm handleSubmit={handleSubmit} questions={questions} />
                </Form>
              )}
          </Formik>
        </div>

      </section>
      <FSDialog open={ open } handleClose={ handleThanksClose }
                withClose={ false } closeOnClick>
        <div className={ contactStyles.thanksContainer }>
          <h2 className={ contactStyles.thanksHeader }>Thanks for reaching out!</h2>
          <p className={ contactStyles.thanksBody }>We’ll get back to you when we’ve
            found our computer.</p>
          <CheckMarkIcon className={ contactStyles.check }/>
        </div>
      </FSDialog>
    </PageLayout>
  );
}

export const query = graphql`
  query careerPage {
    sanityCareers {
      _rawOpenPositions
    }
  }
`;

export default Career;
